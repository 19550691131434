.adapter-unsupported-option {
  .adapter-icon {
    display: inline-block;
    color: var(--colors-accent-600, #7F56D9);
  }

  color: var(--colors-text-text-tertiary-600, #676C7E) !important;
  font-family: "Saans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 20px !important;
  letter-spacing: -0.01em;

  ul {
    list-style: unset;
    padding: 0 14px;
    margin: 14px 0;
    
    li {
      margin-bottom: 8px;
      position: relative;
      
      &::before {
        content: "";
        position: absolute;
        left: -12px;
        top: 8px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--colors-accent-400, #9E77ED);
      }
    }
  }
}

.adapter-memory {
  padding: var(--spacing-lg) var(--spacing-xl);
  border-radius: 12px;
  background: rgba(249, 250, 252, 0.7);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  transition: background-color 0.2s ease;
  
  &:hover {
    background: rgba(249, 250, 252, 0.9);
  }
  
  ul {
    list-style: disc;
    margin: var(--spacing-md) var(--spacing-lg);

    li {
      padding-left: var(--spacing-md);
      margin-left: var(--spacing-md);
      margin-bottom: var(--spacing-sm);
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}