@import "~leaflet/dist/leaflet.css";

.adapter-mapview {
    .leaflet-tile-pane {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    // disable ukraine flag: https://github.com/Leaflet/Leaflet/pull/8109
    .leaflet-control-attribution {
        display: none;
    }

    height: 650px;
    width: 100%;

    .svg-icon {
        &.best-match {
            path {
                stroke: var(--Purple) !important;
            }

            >div::before {
                color: var(--Purple) !important;
            }
        }

        >div::before {
            content: attr(data-before);

            //border: 1px solid red;
            display: block;
            text-align: center;
            font-size: 10px;
            line-height: 18px;
            height: 24px;
            width: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
        }
    }
}